import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SwiperOptions} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-swiper-score-mobile',
  standalone: false,

  templateUrl: './swiper-score-mobile.component.html',
  styleUrl: './swiper-score-mobile.component.scss'
})
export class SwiperScoreMobileComponent implements OnChanges, AfterViewInit{
  @ViewChild ('swiper') swiper: SwiperComponent;
  @Input() numbers: number[] = [];

  @Output() currentStepEmitter = new EventEmitter<number>();

  config: SwiperOptions = {
    slidesPerView: 4,
    centeredSlides: true,
    slideToClickedSlide: true,
    speed: 1000 ,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numbers) {
      if (this.numbers) {
        this.currentStepEmitter.emit(this.numbers[0]);
      }
    }
  }

  ngAfterViewInit(): void {
    this.swiper.swiperRef.on('slideChange', () => {
      const index = this.swiper.swiperRef.activeIndex;
      this.currentStepEmitter.emit(this.numbers[index]);
     });
  }
}
