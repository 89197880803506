<div
  class="loader text-primary"
  role="status"
  [ngStyle]="{
    top: top ? top + 'px' : 'auto',
    left: left ? left + 'px' : 'auto',
    right: right ? right + 'px' : 'auto',
    bottom: bottom ? bottom + 'px' : 'auto'
  }"
  *ngIf="isLoading">
  <span class="sr-only">Carregando...</span>
</div>
