import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, isDevMode, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule, provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {provideServiceWorker} from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Hammer from 'hammerjs';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor.service';
import { ApplicationInsightsModule } from './modules/application-insights/application-insights.module';
import { CredentialsModule } from './modules/credentials/credentials.module';
import { SharedModule } from './modules/shared/shared.module';
import { SignalRService } from './services/signalR/signalR.service';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { CookieService } from 'ngx-cookie-service';
initializeApp(environment.firebase);

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  } as any;
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    CredentialsModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      tapToDismiss: true,
      disableTimeOut: false,
      enableHtml: true,
      titleClass: 'titulo-toast',
      messageClass: 'mensagem-toast',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    NgxMaskModule.forRoot(),
    ApplicationInsightsModule,
    HammerModule,
  ],
  exports: [],
  providers: [
    CookieService,
    SignalRService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    provideClientHydration(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
