import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

const animation = trigger('effect', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
    }),
    animate(
      '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      style({
        transform: 'translateX(0%)',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '200ms cubic-bezier(.1,.5,.65,.99)',
      style({ transform: 'translateX(100%)' })
    ),
  ]),
]);

@Component({
  selector: 'app-generic-nav-drawer-mobile',
  templateUrl: './generic-nav-drawer-mobile.component.html',
  styleUrls: ['./generic-nav-drawer-mobile.component.scss'],
  animations: [animation],
})
export class GenericNavDrawerMobileComponent implements OnDestroy {
  public isOpen = false;
  currentRoute: string;

  constructor(private renderer2: Renderer2) {}
  ngOnDestroy(): void {
    this.removeBackdrop();
  }

  public toggleVisibility(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.insertBackDrop();
      document.body.style.overflow = 'hidden';
    } else {
      this.removeBackdrop();
    }
  }
  public insertBackDrop(): void {
    const backdropElement = this.renderer2.createElement('div') as HTMLElement;
    backdropElement.classList.add('back-drop-static');

    const htmlBody = document.body;
    htmlBody.insertAdjacentElement('beforeend', backdropElement);
  }

  removeBackdrop(): void {
    const backdrop = document.querySelector('.back-drop-static');
    backdrop.remove();
    document.body.style.overflow = 'auto';
  }
}
