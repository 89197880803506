import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { LoginComponent } from './modules/credentials/login/login.component';
import { RecoverPasswordComponent } from './modules/credentials/recover-password/recover-password.component';
import { RedefinePasswordComponent } from './modules/credentials/redefine-password/redefine-password.component';
import { RegistrationPasswordComponent } from './modules/credentials/registration-password/registration-password.component';
import { RegistrationComponent } from './modules/credentials/registration/registration.component';
import { DownloadGuidanceComponent } from './modules/nutritional-guidance/components/download-guidance/download-guidance.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'cadastro_sucesso_login', component: LoginComponent },
  { path: 'cadastro', component: RegistrationComponent },
  { path: 'cadastrar-senha', component: RegistrationPasswordComponent },
  { path: 'recuperar-senha', component: RecoverPasswordComponent },
  { path: 'redefinir-senha/:email', component: RedefinePasswordComponent },
  { path: 'verification', component: RegistrationPasswordComponent },
  { path: 'reset-password', component: RegistrationPasswordComponent },
  {
    path: 'baixar-orientacao/:id/:email/:phone/:pet',
    component: DownloadGuidanceComponent,
  },

  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('../app/modules/home/home.module').then((m) => m.HomeModule),
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
