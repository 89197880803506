import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TutorModel } from './models/tutor-model';
import { AppListResponse, AppResponse } from '../shared/app.response';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoadingService } from '../shared/loading.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TutorService {
  apiUrl = `${environment.baseApimUrl}/tutor`;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService
  ) {}

  createTutor(tutor: TutorModel): Observable<AppResponse<TutorModel>> {
    const headers = this.loadingService.getLoadingHeader();
    tutor.veterinarioId = this.authenticationService.getUserId();
    const url = `${this.apiUrl}/create`;

    return this.http.post<AppResponse<TutorModel>>(url, tutor, { headers });
  }

  editTutor(
    tutor: TutorModel,
    tutorId: number
  ): Observable<AppResponse<TutorModel>> {
    const headers = this.loadingService.getLoadingHeader();
    tutor.veterinarioId = this.authenticationService.getUserId();
    const url = `${this.apiUrl}/${tutorId}/update`;

    return this.http.put<AppResponse<TutorModel>>(url, tutor, { headers });
  }

  getTutors(
    pageNumber: number,
    itensPerPage: number
  ): Observable<AppListResponse<TutorModel>> {
    const headers = this.loadingService.getLoadingHeader();
    const request = {
      SearchExpression: '',
      Filters: [
       {
          Key: 'createdBy',
          Operator: 'Equals',
          Value: this.authenticationService.getUserId().toString()
        }
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: pageNumber,
      PageSize: itensPerPage
    };

    return this.http
      .post<AppListResponse<TutorModel>>(`${this.apiUrl}/list`, request, {
        headers,
      })
      .pipe(map((response) => this.mapTutorWithWhatsapp(response)));
  }

  getTutor(tutorId: number): Observable<TutorModel> {
    const url = `${this.apiUrl}/${tutorId}/detail`;
    return this.http.get<TutorModel>(url);
  }

  filterTutorByNameOrWhatsapp(
    value: string,
    exactSearch: boolean,
    pageNumber = 0,
    itensPerPage = 10
  ): Observable<AppListResponse<TutorModel>> {
    const isWhatsapp = value?.length >= 1 && !isNaN(value as any);
    const key = isWhatsapp ? 'DDDTelefoneMovel' : 'nome';

    const tutorFilter = {
      SearchExpression: '',
      Filters: [
        {
          Key: 'createdBy',
          Operator: 'Equals',
          Value: this.authenticationService.getUserId().toString(),
        },
        {
          Key: key,
          Operator: key === 'DDDTelefoneMovel' ? 'StartsWith' : (exactSearch ? 'Equals' : 'StartsWithFullText'),
          Value: key === 'DDDTelefoneMovel' ? value.substring(0, 2) : value,
        },
      ],
      PageSize: itensPerPage,
      PageNumber: pageNumber,
      SortField: 'nome',
      SortOrder: 2,
    };
    if (key === 'DDDTelefoneMovel' && value.length >= 3) {
      const concatFilter = {
        Key: 'TelefoneMovel',
        Operator: 'StartsWith',
        Value: value.substring(2),
      };

      tutorFilter.Filters.push(concatFilter);
    }

    return this.http
      .post<AppListResponse<TutorModel>>(`${this.apiUrl}/list`, tutorFilter)
      .pipe(map((response) => this.mapTutorWithWhatsapp(response)));
  }

  getTutorsByName(name: string): Observable<AppListResponse<TutorModel>> {
    const tutorFilter = {
      SearchExpression: '',
      Filters: [
        {
          Key: 'nome',
          Operator: 'Equals',
          Value: name,
        },
      ],
      PageSize: 1,
      PageNumber: 0,
      SortField: 'nome',
      SortOrder: 1,
    };

    return this.http.post<AppListResponse<TutorModel>>(
      `${this.apiUrl}/list`,
      tutorFilter
    );
  }

  async tutorsExists(): Promise<boolean> {
    const filterVet = {
      Key: 'createdBy',
      Operator: 'Equals',
      Value: this.authenticationService.getUserId().toString(),
    };

    const tutorListRequest = {
      SearchExpression: null as any,
      Filters: [] as any,
      PageSize: 1,
      PageNumber: 0,
      SortField: 'nome',
      SortOrder: 1,
    };
    tutorListRequest.Filters.push(filterVet);
    const response = await this.http
      .post<AppListResponse<TutorModel>>(
        `${this.apiUrl}/list`,
        tutorListRequest
      )
      .toPromise();

    return response.items.length > 0;
  }

  mapTutorWithWhatsapp(response: AppListResponse<TutorModel>): AppListResponse<TutorModel> {
    return {
      ...response,
      items: response.items.map((tutor) => ({
        ...tutor,

        whatsapp: this.formatNumber(
          tutor.dddTelefoneMovel,
          tutor.telefoneMovel
        ),
      })),
    };
  }

  formatNumber(tutorDDD: string, tutorPhone: string): string {
    if (
      !tutorDDD ||
      !tutorPhone ||
      tutorDDD.length !== 2 ||
      tutorPhone.length <8
    ) {
      return '';
    }

    if (tutorDDD && tutorPhone) {
      let whatsappNumber = tutorDDD + tutorPhone;
      whatsappNumber = whatsappNumber.replace(/\D/g, '');
      whatsappNumber = whatsappNumber.replace(/^(\d{2})(\d)/g, '($1) $2');
      whatsappNumber = whatsappNumber.replace(/(\d)(\d{4})$/, '$1-$2');
      return whatsappNumber;
    } else {
      return '';
    }
  }
}
