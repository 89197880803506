import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CrossDomainSharingService {
  constructor(private authService: AuthenticationService, private cookieService: CookieService) { }

  postCrossDomainMessage() {
    let json: string = '';

    if (!this.authService.isLoggedIn()) {
        this.cookieService.set('userLogged', json, 10, '/', environment.domain);
      return;
    }

    this.authService.loadUserById().subscribe(user => {
      if (user != null && this.authService.isLoggedIn()) {
        json = user.nome + ';' + (user.pictureUrl == null ? '' : user.pictureUrl);
      }

      setTimeout(() => {
        this.cookieService.set('userLogged', json, 10, '/', environment.domain);
      }, 500)
    });
  }
}
