import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-cards',
  templateUrl: './filter-cards.component.html',
  styleUrls: ['./filter-cards.component.scss'],
})
export class FilterCardsComponent {
  @Input() icon: string;
  @Input() totalData: number;
  @Input() titleCard: string;
  @Output() filterSelected = new EventEmitter();
  selected: boolean = false;
  constructor() {}

  applyFilter() {
    this.selected = !this.selected;
    this.filterSelected.emit(this.selected);
  }
}
