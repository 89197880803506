import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {NotificationService} from '../services/notification/notification.service';
import {ErrorHttpResponse} from './models/error-http-models';
import {AuthenticationService} from '../services/authentication/authentication.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorsMessages: { [key: number]: string } = {
    0: 'Algo deu errado, tente novamente mais tarde.',
    400: 'Erro de validação. Revise os dados e tente novamente.',
    401: 'Não autorizado, por favor faça login novamente.',
    403: 'Operação não permitida.',
    404: 'Erro de validação. Não foi encontrado a rota da requisição.',
    500: 'Algo deu errado, tente novamente mais tarde.',
    504: 'Algo deu errado, o serviço pode estar temporariamente em manutenção',
  };
  constructor(private authService: AuthenticationService, private notificationService: NotificationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: ErrorHttpResponse): Observable<HttpEvent<any>> => {
        const errors = error;
        const statusCode = errors.status;
        const errorMessage = errors.error?.errors ? errors.error.errors : this.errorsMessages[statusCode];

        if (this.errorsMessages) {
          if (Array.isArray(errorMessage)) {
            for (const err of errorMessage) {
              this.notificationService.error(err);
            }
          } else {
            if (statusCode.toString().startsWith('4') && statusCode !== 401) {
              this.notificationService.warning(errorMessage);
            } else {
              this.notificationService.error(errorMessage);
            }
          }
        } else {
          this.notificationService.error(this.errorsMessages[0]);
        }

        return throwError(error);
      })
    );
  }
}
