import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-hint-modal',
  templateUrl: './hint-modal.component.html',
  styleUrls: ['./hint-modal.component.scss']
})
export class HintModalComponent {
  message: string;
  constructor(private modalRef: BsModalRef) { }

  close(): void {
    this.modalRef.hide();
  }

}
