<main class="modal-container offcanvas">
  <div class="modal-header">
    <h5 class="modal-title">Alterar {{ titleProduct }}</h5>
    <button
      type="button"
      class="close pull-right"
      (click)="close()"
      aria-label="Fechar"
    >
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>
  <div class="offcanvas-body">
    <form action="" class="form-group form">
      <input
        type="text"
        name="search"
        id=""
        class="form-control search-field"
        autocomplete="off"
        placeholder="Procurar alimento"
        [(ngModel)]="searchValue"
        (ngModelChange)="productSearch.next($event)"
      />
      <i class="bi bi-search search__icon"></i>
      <a (click)="openFilter()" class="btn-submit btn-filter">
         <span class="filter-label no-margin">Filtrar</span>
        <i class="bi bi-funnel icon__filter no-margin"></i>
        <span
          *ngIf="totalFilters"
          class="qtd-filters"
          [ngStyle]="
            totalFilters > 9
              ? { width: '18px', height: '18px' }
              : { width: '15px', height: '15px' }
          "
        >
          {{ totalFilters }}
        </span>
      </a>
    </form>
      <div
      *ngIf="showProductsTotalTags"
      class="search-return-container">
        <label *ngIf="totalCount === 0 || totalCount > 1" class="search-return-label"> {{ totalCount }} resultados encontrados </label>
        <label *ngIf="totalCount === 1" class="search-return-label"> {{ totalCount }} resultado encontrado </label>
      </div>

    <section
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="0.6"
      class="section-product-content"
      (scrolled)="onScroll()"
    >
      <div *ngIf="products">
        <div
          class="products-content"
          [class.first]="first"
          *ngFor="let product of products; let first = first"
          (click)="selectProduct(product, $event)"
        >
          <div class="product-card">
            <div class="product-card-image">
              <img src="{{ product.productImageUrl }}" alt="alimento seco" />

              <div *ngIf="mobileScreen" class="change-products-more-details">
                <a
                  class="btn-link"
                  href="{{ product.productUrl }}"
                  target="_blank"
                >
                  <i class="bi bi-file-earmark-richtext"></i>
                  <span class="link-title">Infos técnicas</span>
                </a>
              </div>
            </div>
            <div class="product-card-content">
              <div>
                <h4 class="card-title">{{ product.shortName }}</h4>
                <div class="card-tags">
                  <span
                    *ngIf="product.ageGroupFormattedName"
                    class="container-tags"
                  >
                    <caption>
                      {{
                      product.ageGroupFormattedName
                      }}
                    </caption>
                  </span>
                  <span *ngIf="product.ageGroupByAge" class="container-tags">
                    <caption>
                      {{
                      product.ageGroupByAge.name
                      }}
                    </caption>
                  </span>
                  <span
                    *ngIf="product.sizeFormattedName && product.specieName === 'Dog'"
                    class="container-tags"
                  >
                    <caption>
                      {{
                        product.sizeFormattedName
                      }}
                    </caption>
                  </span>
                  <span *ngIf="product.flavor" class="container-tags">
                    <caption>
                      {{
                        product.flavor
                      }}
                    </caption>
                  </span>
                </div>
              </div>

              <div *ngIf="!mobileScreen" class="change-products-more-details">
                <a
                  class="btn-link"
                  href="{{ product.productUrl }}"
                  target="_blank"
                >
                  <i class="bi bi-file-earmark-richtext"></i>
                  <span class="link-title">Infos técnicas</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<app-generic-nav-drawer #drawer>
  <app-filter-change-product
    *ngIf="drawer.isOpen"
    (closeChange)="drawer.toggleVisibility()"
    [typeProduct]="typeProduct"
    [speciePet]="speciePet"
    (filterSelectedEmmitter)="filter($event)"
    [selectedFilters]="productFilter"
  >
  </app-filter-change-product>
</app-generic-nav-drawer>
