import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgToggleModule } from 'ng-toggle-button';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SwiperModule } from 'swiper/angular';
import { DateBrPipe } from '../../pipes/date-br.pipe';
import { FilterPipe } from '../../pipes/filter.pipe';
import { WeightPipe } from '../../pipes/weight.pipe';
import { ModalSendEmailComponent } from '../nutritional-guidance/components/modal-send-email/modal-send-email.component';
import { AvatarUserComponent } from './components/avatar-user/avatar-user.component';
import { BackgroundComponent } from './components/background/background.component';
import { CalendarDesktopComponent } from './components/calendar-desktop/calendar-desktop.component';
import { CalendarMobileComponent } from './components/calendar-mobile/calendar-mobile.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { ChangeProductComponent } from './components/change-product/change-product.component';
import { ConfirmEmailMessageComponent } from './components/confirm-email-message/confirm-email-message.component';
import {
  TableCellTemplateDirective,
  TableColumnComponent,
  TableComponent,
  TableExpandedRowComponent,
  TableExpandedTriggerDirective,
  TableHeaderTemplateDirective,
} from './components/data-table/data-table.component';
import { PaginatorComponent } from './components/data-table/paginator-table.component';
import { FieldValidateComponent } from './components/field-validate/field-validate.component';
import { FilterCardsComponent } from './components/filter-cards/filter-cards.component';
import { FilterChangeProductComponent } from './components/filter-change-product/filter-change-product.component';
import { FilterOrientationMobileComponent } from './components/filter-orientation-mobile/filter-orientation-mobile.component';
import { FooterComponent } from './components/footer/footer.component';
import { GenericFooterComponent } from './components/generic-footer/generic-footer.component';
import { GenericNavDrawerMobileComponent } from './components/generic-nav-drawer-mobile/generic-nav-drawer-mobile.component';
import { GenericNavDrawerComponent } from './components/generic-nav-drawer/generic-nav-drawer.component';
import { GenericTagsComponent } from './components/generic-tags/generic-tags.component';
import { GenericTipComponent } from './components/generic-tip/generic-tip.component';
import { GradualChangeMobileComponent } from './components/gradual-change-mobile/gradual-change-mobile.component';
import { GradualChangeComponent } from './components/gradual-change/gradual-change.component';
import { InfiniteSelectComponent } from './components/infinite-select/infinite-select.component';
import { ListFilterButtonComponent } from './components/list-filter-button/list-filter-button.component';
import { MobileDatepickerComponent } from './components/mobile-datepicker/mobile-datepicker.component';
import { MobileModalOverlayComponent } from './components/mobile-modal-overlay/mobile-modal-overlay.component';
import { NewBreadcrumbsComponent } from './components/new-breadcrumbs/new-breadcrumbs.component';
import { PetActivityLevelComponent } from './components/pet-activity-level/pet-activity-level.component';
import { PwaInstallComponent } from './components/pwa-install/pwa-install.component';
import { RadioButtonGenericComponent } from './components/radio-button-generic/radio-button-generic.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SelectTagsComponent } from './components/select-tags/select-tags.component';
import { SoonComponent } from './components/soon/soon.component';
import { NextFieldDirective } from './directives/next-field.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { OnlyTextDirective } from './directives/only-text.directive';
import { noWhiteSpaceDirective } from './directives/without-space.directive';
import { DeleteEstablishmentComponent } from './modals/delete-establishment/delete-establishment.component';
import { EstablishmentModalComponent } from './modals/establishment-modal/establishment-modal.component';
import { HintModalComponent } from './modals/hint-modal/hint-modal.component';
import { PetModalComponent } from './modals/pet-modal/pet-modal.component';
import { RegisterTutorNumberModalComponent } from './modals/register-tutor-number-modal/register-tutor-number-modal.component';
import { SendEmailNutritionalGuidanceComponent } from './modals/send-email-nutritional-guidance/send-email-nutritional-guidance.component';
import { TutorModalComponent } from './modals/tutor-modal/tutor-modal.component';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { ClinicalCharacteristicsComponent } from './components/clinical-characteristics/clinical-characteristics.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { PetStagesDiseaseComponent } from './components/pet-stages-disease/pet-stages-disease.component';
import { ScoreComponent } from './components/score/score.component';
import { SwiperScoreMobileComponent } from './components/swiper-score-mobile/swiper-score-mobile.component';
import { OverflowHiddenDirective } from './directives/overflow-hidden.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UploadClinicLogoComponent } from './components/upload-logo/upload-clinic-logo.component';
import { UploadImageComponent } from "./components/upload-logo/upload-image.component";
import { CustomRegexDirective } from './directives/custom-regex.directive';
import { GenericLoadingComponent } from "./components/generic-loading/generic-loading.component";
import { GenericDoneComponent } from './components/generic-done/generic-done.component';

defineLocale('pt-br', ptBrLocale);
registerLocaleData(localePt);
@NgModule({
  declarations: [
    FooterComponent,
    GenericTagsComponent,
    BackgroundComponent,
    NextFieldDirective,
    OnlyNumbersDirective,
    CustomRegexDirective,
    OnlyTextDirective,
    FieldValidateComponent,
    InfiniteSelectComponent,
    SelectTagsComponent,
    FilterPipe,
    TutorModalComponent,
    PetModalComponent,
    GenericNavDrawerComponent,
    ChangeProductComponent,
    EstablishmentModalComponent,
    ScrollToTopComponent,
    NewBreadcrumbsComponent,
    GenericFooterComponent,
    SendEmailNutritionalGuidanceComponent,
    AvatarUserComponent,
    SoonComponent,
    GenericNavDrawerMobileComponent,
    HintModalComponent,
    GenericNavDrawerMobileComponent,
    GenericTipComponent,
    HintModalComponent,
    DateBrPipe,
    TableComponent,
    TableCellTemplateDirective,
    TableHeaderTemplateDirective,
    TableColumnComponent,
    TableExpandedRowComponent,
    TableExpandedTriggerDirective,
    PaginatorComponent,
    noWhiteSpaceDirective,
    ConfirmEmailMessageComponent,
    FilterChangeProductComponent,
    FilterCardsComponent,
    PwaInstallComponent,
    RadioButtonGenericComponent,
    CalendarDesktopComponent,
    CalendarMobileComponent,
    CalendarViewComponent,
    PetActivityLevelComponent,
    RegisterTutorNumberModalComponent,
    DeleteEstablishmentComponent,
    ModalSendEmailComponent,
    FilterOrientationMobileComponent,
    GradualChangeComponent,
    ListFilterButtonComponent,
    GradualChangeMobileComponent,
    MobileModalOverlayComponent,
    MobileDatepickerComponent,
    GradualChangeMobileComponent,
    WeightPipe,
    OverflowHiddenDirective,
    ClinicalCharacteristicsComponent,
    SwiperScoreMobileComponent,
    PetStagesDiseaseComponent,
    ScoreComponent,
    UploadClinicLogoComponent,
    UploadImageComponent,
    GenericLoadingComponent,
    GenericDoneComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    NgSelectModule,
    CarouselModule.forRoot(),
    RatingModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    InfiniteScrollModule,
    SwiperModule,
    ButtonsModule.forRoot(),
    NgToggleModule.forRoot(),
    GenericTableComponent,
    NgxSkeletonLoaderModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FooterComponent,
    FontAwesomeModule,
    NgSelectModule,
    CarouselModule,
    RatingModule,
    BsDropdownModule,
    GenericTagsComponent,
    BsDatepickerModule,
    TypeaheadModule,
    PaginationModule,
    TooltipModule,
    NgxSpinnerModule,
    BackgroundComponent,
    NextFieldDirective,
    OnlyNumbersDirective,
    CustomRegexDirective,
    OnlyTextDirective,
    FieldValidateComponent,
    InfiniteSelectComponent,
    SelectTagsComponent,
    FilterPipe,
    GenericNavDrawerComponent,
    ChangeProductComponent,
    EstablishmentModalComponent,
    ScrollToTopComponent,
    NewBreadcrumbsComponent,
    GenericFooterComponent,
    SendEmailNutritionalGuidanceComponent,
    AvatarUserComponent,
    SoonComponent,
    GenericNavDrawerMobileComponent,
    GenericTipComponent,
    GenericNavDrawerMobileComponent,
    DateBrPipe,
    TableComponent,
    TableCellTemplateDirective,
    TableHeaderTemplateDirective,
    TableColumnComponent,
    TableExpandedRowComponent,
    TableExpandedTriggerDirective,
    PaginatorComponent,
    noWhiteSpaceDirective,
    ConfirmEmailMessageComponent,
    FilterChangeProductComponent,
    FilterCardsComponent,
    PwaInstallComponent,
    RadioButtonGenericComponent,
    InfiniteScrollModule,
    CalendarDesktopComponent,
    CalendarMobileComponent,
    PetActivityLevelComponent,
    ModalSendEmailComponent,
    NgToggleModule,
    GradualChangeComponent,
    ListFilterButtonComponent,
    GradualChangeMobileComponent,
    FilterOrientationMobileComponent,
    MobileModalOverlayComponent,
    MobileDatepickerComponent,
    WeightPipe,
    OverflowHiddenDirective,
    ClinicalCharacteristicsComponent,
    SwiperScoreMobileComponent,
    PetStagesDiseaseComponent,
    GenericTableComponent,
    ScoreComponent,
    NgxSkeletonLoaderModule,
    UploadClinicLogoComponent,
    UploadImageComponent,
    GenericLoadingComponent,
    GenericDoneComponent
  ],
  providers: [BsModalService, { provide: LOCALE_ID, useValue: 'pt' }, DateBrPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
