import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { States } from '../../../services/static/models/states';
import { StaticService } from '../../../services/static/static.service';
import { ValidateCustomCpf, ValidateOnlyText } from '../../shared/validators/type-validators';
import { CompleteRegisterModel } from '../../../services/authentication/models/complete-register.model';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification/notification.service';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
})
export class RegistrationCompleteComponent implements OnInit {
  @Input() email: string;
  listStates: States[] = [];
  registrationForm: FormGroup;
  isMobile = false;
  isStudent = false;
  isStudentSelected = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private staticService: StaticService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getListStatesOfBrazil();
    this.isMobile = window.innerWidth <= 900;
  }

  initializeForm(): void {
    this.registrationForm = this.fb.group({
      name: [null, [Validators.required, ValidateOnlyText]],
      email: [null, [Validators.required]],
      crmvNumber: [null, [Validators.required, Validators.minLength(4)]],
      state: [null, [Validators.required]],
      cpf: [null, [ValidateCustomCpf, Validators.required]],
      acceptedTerms: [true, Validators.requiredTrue]
    })

    this.registrationForm.get('email').setValue(this.email);
  }

  getListStatesOfBrazil(): void {
    this.staticService.getAllStatesOfBrazil().subscribe((res) => {
      this.listStates = res;
    })
  }

  submit(): void {
    if(this.isMobile && !this.isStudentSelected) {
      this.isStudentSelected = true;
      return;
    }

    if(this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();
      return;
    }

    this.completeRegister();
  }

  completeRegister(): void {
    this.authService.completeRegister(this.registerRequest).subscribe(() => {
      this.notificationService.success('Cadastro realizado com sucesso!');
      void this.router.navigate(['cadastro_sucesso_login']);
    })
  }

  get registerRequest(): CompleteRegisterModel {
    return {
      nome: this.registrationForm.get('name').value,
      email: this.registrationForm.get('email').value,
      numeroCrmv: this.registrationForm.get('crmvNumber').value,
      estadoCrmv: this.registrationForm.get('state').value,
      cpf: this.registrationForm.get('cpf').value,
      aceiteTermos: this.registrationForm.get('acceptedTerms').value
    }
  }
}
