<div class="btn-container" [ngClass]="mobileColumns === 1 ? 'one-column' : 'two-columns'">
  <div
    id="id-radio-button-generic-container"
    class="btn-group"
    *ngFor="let option of options"
  >
    <div
      class="btn btn-radio"
      id="id-radio-button-generic-btn"
      tabindex="0"
      [class.selected]="selected === option"
      [class.disabled]="option.disabled"
      role="button"
      (click)="sendEvent(option)"
    >
      <img
        id="id-radio-button-generic-icon"
        *ngIf="option.icon"
        class="icon-button"
        [src]="option.icon"
        alt="Ícone do botão"
      />
      <label
        id="id-radio-button-generic-description"
        *ngIf="option.description"
        class="button-description"
      >{{ option.description }}
      </label>
    </div>
  </div>
</div>

