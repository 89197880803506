import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Stage } from 'src/app/services/calculator/models/calculator-clinical-characteristics-model';

@Component({
  selector: 'app-pet-stages-disease',
  standalone: false,
  templateUrl: './pet-stages-disease.component.html',
  styleUrl: './pet-stages-disease.component.scss'
})
export class PetStagesDiseaseComponent implements OnChanges {
  @Input() label: string;
  @Input() showStagesDisease = true;
  @Input() required = true;

  @Input() stagesDiseases: Stage[];
  @Output() stageDiseaseSelected  = new EventEmitter<number>();

  stageDiseaseId: number;

  ngOnChanges(changes: SimpleChanges): void {
    this.stageDiseaseId = null;
  }

  handleOptionSelected(): void {
    this.stageDiseaseSelected.next(this.stageDiseaseId);
  }
}
