import {
  FilterModel,
  BaseListRequest,
} from 'src/app/services/shared/base-list-request';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppListResponse } from '../shared/app.response';
import { ProductModel } from './models/product-model';
import { FiltersProductsModel } from './models/filters-products-model';
import { ProductAdvancedFilterModel } from './models/product-advanced-filter-model';
import { ProductLineModel } from './models/product-line-model';
import { SpecieEnum } from 'src/app/modules/shared/enums/specie.enum';
import { TypeProductEnum } from '../../modules/shared/enums/type-product.enum';
import {LoadingService} from '../shared/loading.service';
import { Product } from '../calculator/models/calculator-product-result-model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private apiUrl = `${environment.baseApimUrl}/produto`;
  private apiCalculatorUrl = `${environment.apiCalculator}/product`;

  constructor(private http: HttpClient, private loadingService: LoadingService) { }

  listOfProducts(
    filters?: FilterModel[],
    itensPerPage = 10,
    pageNumber = 0
  ): Observable<AppListResponse<ProductModel>> {
    const productActive: FilterModel = {
      Key: 'Ativo',
      Operator: 'Equals',
      Value: 'true',
    };
    filters.push(productActive);

    const url = `${this.apiUrl}/list`;
    const searchObject: BaseListRequest = {
      SearchExpression: '',
      Filters: [...filters],
      SortField: 'linhaProdutoPriority',
      SortOrder: 0,
      PageNumber: pageNumber,
      PageSize: itensPerPage,
    };

    return this.http.post<AppListResponse<ProductModel>>(url, searchObject);
  }


  getLineProducts(name: string, specie: SpecieEnum, typeProduct?: TypeProductEnum): Observable<AppListResponse<ProductLineModel>> {
    const url = `${this.apiCalculatorUrl}/lines/list`;
    const searchObject: BaseListRequest =
    {
      SearchExpression: null,
      Filters: [
        {
          Key: 'name',
          Operator: 'ContainsFullText',
          Value: name
        },
        {
          Key: 'specie',
          Operator: 'NotEquals',
          Value: specie === SpecieEnum.DOG ? SpecieEnum.CAT : SpecieEnum.DOG
        }
      ],
      SortField: 'priority',
      SortOrder: 0,
      PageNumber: 0,
      PageSize: 99
    };

    if (typeProduct) {
      searchObject.Filters.push({
        Key: 'productType',
        Operator: 'Equals',
        Value: typeProduct
      });
    }
    return this.http.post<AppListResponse<ProductLineModel>>(url, searchObject);
  }

  getFilterByProducts(
    specie: number,
    typeProduct: number
  ): Observable<FiltersProductsModel> {
    const url = `${this.apiCalculatorUrl}/filters`;

    return this.http.get<FiltersProductsModel>(
      `${url}/${specie}/${typeProduct}`
    );
  }

  getFilterAdvanced(
    searchObject: ProductAdvancedFilterModel, loading = true
  ): Observable<AppListResponse<Product>> {
    const headers = loading ? this.loadingService.getLoadingHeader() : null;
    const url = `${this.apiCalculatorUrl}/advanced/list`;
    return this.http.post<AppListResponse<Product>>(url, searchObject, { headers });
  }

  getMessageProduct(productId: number, activityLevel: number, showLoading: boolean = false): Observable<any> {
    let headers = new HttpHeaders();
    activityLevel = activityLevel == null ? 0 : activityLevel;

    if (showLoading){
      headers = this.loadingService.getLoadingHeader();
    }
    const url = `${this.apiCalculatorUrl}/getProductMessage/${productId}/${activityLevel}`;
    return this.http.get<any>(url, {
      headers
    });
  }
}
