import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import {
  Lines,
  FiltersProductsModel,
  Age,
  Size, AgeByAge,
} from 'src/app/services/product/models/filters-products-model';
import { ProductAdvancedFilterModel } from 'src/app/services/product/models/product-advanced-filter-model';
import { ProductService } from 'src/app/services/product/product.service';
import { TypeProductEnum } from '../../enums/type-product.enum';

@Component({
  selector: 'app-filter-change-product',
  templateUrl: './filter-change-product.component.html',
  styleUrls: ['./filter-change-product.component.scss'],
})
export class FilterChangeProductComponent implements OnInit {
  isCollapsedLines = false;
  isCollapsedAges = false;
  isCollapseSizes = false;
  isCollapseCharacteristics = false;
  isCollapseFlavors = false;
  @Input() typeProduct: TypeProductEnum;
  @Input() speciePet: number;
  @Input() selectedFilters: ProductAdvancedFilterModel = {};
  data: FiltersProductsModel;
  @Output() closeChange = new EventEmitter<boolean>();
  @Output() filterSelectedEmmitter =
    new EventEmitter<ProductAdvancedFilterModel>();
  filtersForm: UntypedFormGroup;
  lines: Lines[] = [];
  ages: Age[] = [];
  agesByAge: AgeByAge[] = [];
  sizes: Size[] = [];
  flavors: string[] = [];
  formArrayNames: string[] = [
    'lines',
    'ages',
    'flavors',
    'sizes',
    'agesByAge'
  ];
  titleFilter: { [key in TypeProductEnum]: string } = {
    [TypeProductEnum.DRY_FOOD]: 'alimento seco',
    [TypeProductEnum.COOKIE]: 'cookie',
    [TypeProductEnum.WET_FOOD]: 'alimento úmido',
  };

  constructor(
    private productService: ProductService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getDataFilterProducts();
    this.initializeForm();
    this.setSelecteFilter();
  }
  close(): void {
    this.closeChange.emit(true);
  }

  getDataFilterProducts(): void {
    this.productService
      .getFilterByProducts(this.speciePet, this.typeProduct)
      .subscribe((res) => {
        this.data = res;
        this.lines = this.data.lines;
        this.ages = this.data.ages;
        this.sizes = this.data.sizes;
        this.flavors = this.data.flavors;
        this.agesByAge = this.data.agesByAge;
      });
  }

  initializeForm(): void {
    this.filtersForm = this.fb.group({
      lines: this.fb.array([]),
      ages: this.fb.array([]),
      sizes: this.fb.array([]),
      flavors: this.fb.array([]),
      agesByAge: this.fb.array([]),
    });
  }

  onCheckboxChange(type: string, e: any) {
    const filterForm = this.filtersForm.get(type) as UntypedFormArray;

    if (e.target.checked) {
      filterForm.push(new UntypedFormControl(e.target.value));
    } else {
      let i = 0;
      filterForm.controls.forEach((item: AbstractControl) => {
        if (item.value == e.target.value) {
          filterForm.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  setSelecteFilter(): void {
    this.formArrayNames.forEach((formArrayName) => {
      if (this.selectedFilters.hasOwnProperty(formArrayName)) {
        const formArray = this.filtersForm.get(formArrayName) as UntypedFormArray;
        const values = (this.selectedFilters as any)[formArrayName];

        values.forEach((value: string) => {
          formArray.push(new UntypedFormControl(value));
        });
      }
    });
  }

  isCheckboxChecked(type: string, valueId: string | number): boolean {
    const formArray = this.filtersForm.get(type) as UntypedFormArray;
    return formArray.value.some((value: string) => value == valueId);
  }

  submit(): void {
    const agesByAge = this.filtersForm.value.agesByAge.map((ageName: string) => {
      return this.agesByAge.find(age => age.name === ageName);
    }).filter((age: AgeByAge) => age !== null && age !== undefined);

    const filters = {
      ...this.filtersForm.value,
      agesByAge
    };
    this.filterSelectedEmmitter.emit(filters);
    this.close();
  }

  resetFilter(): void {
    this.formArrayNames.forEach((formArrayName) => {
      const formArray = this.filtersForm.get(formArrayName) as UntypedFormArray;
      while (formArray.length !== 0) {
        formArray.removeAt(0);
      }
    });
  }
}
