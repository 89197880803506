import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  imgBackground: string =
    '../../../../assets/images/background/404-background.png';
  expiredToken: boolean = false;
  previousUrl: string = '';

  constructor(private router: Router) {
    this.checkUrl();
  }

  getHome(): void {
    this.router.navigate(['/home']);
  }

  checkUrl() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.previousUrl = this.router.url;
        if (this.previousUrl === '/404?expiredtoken=invalid') {
          this.expiredToken = true;
        }
      });
  }
}
