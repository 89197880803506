<div class="form request-change" *ngIf="type == 'changePassword'">
  <h1 class="form-title mb-0">Confirme seu e-mail</h1>
  <p class="description-email-confirm">
    Agora precisamos que confirme seu e-mail acessando o link que enviamos para:
  </p>
  <p class="success-email">{{email}}</p>
  <button class="resend-email" (click)="resendEmail()">Não recebeu? Envie novamente.</button>
</div>



<form *ngIf="type == 'register'" class="form" [formGroup]="registrationForm">
  <h1 class="form-title mb-0">Verifique seu e-mail para obter o código</h1>
  <p class="description-email-confirm">
    Informe abaixo o código de seis caracteres enviado para o e-mail <b>{{email}}</b>
  </p>
  <div class="inputs-token">
    <input formControlName="number1" #numberOne class="input-token" type="text" maxlength="1"
      oninput="this.value = this.value.toUpperCase()" (keyup)="onDigitInput($event)" (paste)="onPaste($event)" />
    <input formControlName="number2" #numberTwo class="input-token" type="text" maxlength="1"
      oninput="this.value = this.value.toUpperCase()" (keyup)="onDigitInput($event)" />
    <input formControlName="number3" #numberOThree class="input-token" type="text" maxlength="1"
      oninput="this.value = this.value.toUpperCase()" (keyup)="onDigitInput($event)" />

    <input formControlName="number4" #numberFour class="input-token" type="text" maxlength="1"
      oninput="this.value = this.value.toUpperCase()" (keyup)="onDigitInput($event)" />
    <input formControlName="number5" #numberFive class="input-token" type="text" maxlength="1"
      oninput="this.value = this.value.toUpperCase()" (keyup)="onDigitInput($event)" />
    <input formControlName="number6" #numberSix class="input-token" type="text" maxlength="1"
      oninput="this.value = this.value.toUpperCase()" (keyup)="onDigitInput($event)" />
  </div>

  <button (click)="continue()" class="btn-submit">Continuar</button>

  <hr />

  <span class="resendemail">Não recebeu o código? <b *ngIf="display === ''"><a href="javascript:void()"
        (click)="resendEmail()">Envie novamente.</a></b><span class="timer-text">{{ display }}</span></span>
</form>
