<app-background urlNavigation="/login" (backEmitter)="onBack()">
  <div class="form-container" *ngIf="!registerSuccessfully && !completeRegister">
    <form class="form" [formGroup]="registrationForm">
      <h1 class="form-title">Crie sua conta</h1>
      <div>
        <label for="email" class="field-validate__label">Seu melhor e-mail</label>
        <div class="input-wrapper outlined"
          [ngClass]="{'invalid-input' : !this.registrationForm.get('email').valid && this.registrationForm.get('email').touched}">
          <input #email type="text" id="email" formControlName="email" (change)="setFormStorage('email')"
            [nextField]="password" />
        </div>
        <div style="height: 18px;">
          <span *ngIf="!this.registrationForm.get('email').valid && this.registrationForm.get('email').touched"
            class="span-error right">
            E-mail inválido
          </span>
        </div>
      </div>
      <div class="pt-4">
        <label class="field-validate__label" for="password">Senha</label>
        <div class="input-wrapper outlined position-relative"
          [ngClass]="{'invalid-input' : !this.registrationForm.get('password').valid && this.registrationForm.get('password').touched} ">
          <input #password [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password"
            (input)="setFormStorage('password')" />
          <button class="button-password text-primary position-absolute fs-2"><i
              [class]="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
              (click)="showPassword = !showPassword"></i></button>
        </div>
      </div>
      <div>
        <div style="height: 18px;">
          <span *ngIf="!this.registrationForm.get('password').valid && this.registrationForm.get('password').touched"
            class="span-error right">
            Senha inválida
          </span>
        </div>
      </div>
      <div class="validator-container">
        <p class="validator">
          <i [class]="isMinLengthValid ? 'bi bi-check text-success' : 'bi bi-x text-danger'"></i>
          Mínimo de 8 caracteres
        </p>
        <p class="validator">
          <i [class]="isUppercaseValid ? 'bi bi-check text-success' : 'bi bi-x text-danger'"></i>
          Letra maiúscula
        </p>
        <p class="validator">
          <i [class]="isLowercaseValid ? 'bi bi-check text-success' : 'bi bi-x text-danger'"></i>
          Letra minúscula
        </p>
        <p class="validator">
          <i [class]="isNumberValid ? 'bi bi-check text-success' : 'bi bi-x text-danger'"></i>
          Número
        </p>
        <p class="validator">
          <i [class]="isSpecialCharValid ? 'bi bi-check text-success' : 'bi bi-x text-danger'"></i>
          Caractere especial
        </p>
      </div>
      <div class="d-flex">
        <p class="termos">Criando uma conta você concorda com nossos <a [href]="termsPdfLink" target="_blank"
            rel="noopener noreferrer">termos de uso e com nossas politicas de privacidade</a></p>
      </div>
      <button class="btn-submit" (click)="onSubmit()">Continuar</button>
      <div class="register-redirect">
        Já possui uma conta? <a [routerLink]="['/login']">Acesse agora</a>
      </div>
    </form>
  </div>

  <section class="form-container" *ngIf="registerSuccessfully || completeRegister">
    <app-confirm-email-message *ngIf="registerSuccessfully" [email]="registrationForm.controls['email'].value" type="register" (tokenValidated)="onTokenValidated()">
    </app-confirm-email-message>

    <app-registration-complete *ngIf="completeRegister" [email]="registrationForm.controls['email'].value"></app-registration-complete>
  </section>
</app-background>
