import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {HeaderTable} from './model/generic-table.model';

@Component({
  selector: 'app-generic-table',
  standalone: true,
  imports: [AccordionModule, CommonModule],
  templateUrl: './generic-table.component.html',
  styleUrl: './generic-table.component.scss',
})
export class GenericTableComponent implements OnChanges {
  openTable: boolean = false;
  mobileScreen: boolean;
  screenWidth: number;
  @Input() dataTable: any[];
  @Input() headerTable: HeaderTable[] = [];
  contArr: any[];
  hdrArr: HeaderTable[] = [];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataTable'] || changes['headerTable']) {
      this.configureTable();
    }
  }

  public configureTable(): void {
    if (this.headerTable !== undefined) {
      this.hdrArr = [];
      this.headerTable.forEach((v) => {
        if (v.visible) {
          const obj = new HeaderTable();
          (obj.name = v.name),
            (obj.prop = v.prop),
            (obj.visible = v.visible),
            (obj.template = v.template),
            this.hdrArr.push(obj);
        }
      });
    }
    let i = 0;
    for (i; i < this.dataTable.length; i++) {
      const keys = Object.keys(this.dataTable[i]);
      const obj: any = {};
      keys.forEach((v) => {
        obj[v] = this.dataTable[i][v];
      });

    }
  }

  public getPropByString(obj: any, propString: string): any {
    if (!propString) {
      return obj;
    }

    let prop;
    let index = 0;
    const props = propString.split('.');
    for (let i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];
      index = i;
      const candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[index]];
  }
}
