<form class="form" [formGroup]="registrationForm">
  <h1 class="form-title">Personalize seu PremieRvet<sup>&reg;</sup></h1>
  <ng-container *ngIf="!isMobile; else mobileTemplate">
    <div class="form-flex gap-0 mb-4">
      <button class="select-button veterinarian selected">Sou médico-veterinário</button>
      <button class="select-button student" disabled>Sou estudante (em breve)</button>
    </div>
    <div>
      <field-validate [showAsterisk]="false" fieldName="Nome Completo">
        <input type="text" id="name" onlyText formControlName="name" placeholder="Informe o seu nome" maxlength="80" [nextField]="cpf" />
      </field-validate>
    </div>
    <div>
      <field-validate [showAsterisk]="false" fieldName="CPF">
        <input #cpf type="text" id="cpf" formControlName="cpf" mask="000.000.000-00" placeholder="000.000.000-00" [nextField]="crmv" />
      </field-validate>
    </div>
    <div class="form-flex">
      <field-validate [showAsterisk]="false" fieldName="CRMV">
        <input #crmv numbersOnly type="text" id="crmvNumber" formControlName="crmvNumber" placeholder="00000" maxlength="5" minlength="4"  />
      </field-validate>
      <field-validate [showAsterisk]="false" fieldName="UF" for="state-crmv" class="uf__field">
        <select id="state-crmv" placeholder="MG" formControlName="state">
          <option selected [value]="null">Selecione</option>
          <option *ngFor="let state of listStates" [value]="state.name">{{ state.name }}</option>
        </select>
      </field-validate>
    </div>

    <button id="id-register-btn-submit" type="submit" class="btn btn-primary" (click)="submit()">
      <span>Começar</span>
      <i class="bi bi-check-circle"></i>
    </button>
  </ng-container>

  <ng-template #mobileTemplate>
    <div *ngIf="!isStudentSelected">
      <div class="mobile-select" [class.selected]="!isStudent">
        <label>
          <input type="radio" name="studentStatus" [(ngModel)]="isStudent" [ngModelOptions]="{standalone: true}" [value]="false" />
          Sou médico-veterinário
        </label>
      </div>

      <div class="mobile-select disabled" [class.selected]="isStudent">
        <label>
          <input type="radio" name="studentStatus" disabled [(ngModel)]="isStudent" [ngModelOptions]="{standalone: true}" [value]="true" />
          Sou estudante (em breve)
        </label>
      </div>
    </div>

    <ng-container *ngIf="isStudentSelected">
      <div>
        <field-validate [showAsterisk]="false" fieldName="Nome Completo">
          <input type="text" id="name" onlyText formControlName="name" placeholder="Informe o seu nome" maxlength="80" [nextField]="cpf" />
        </field-validate>
      </div>
      <div>
        <field-validate [showAsterisk]="false" fieldName="CPF">
          <input #cpf type="text" id="cpf" formControlName="cpf" mask="000.000.000-00" placeholder="000.000.000-00" [nextField]="crmv" />
        </field-validate>
      </div>

      <div>
        <field-validate [showAsterisk]="false" fieldName="CRMV">
          <input #crmv numbersOnly type="text" id="crmvNumber" formControlName="crmvNumber" placeholder="00000" maxlength="5" minlength="4"  />
        </field-validate>
      </div>

      <div>
        <field-validate [showAsterisk]="false" fieldName="UF" for="state-crmv" class="uf__field">
          <select id="state-crmv" placeholder="MG" formControlName="state">
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let state of listStates" [value]="state.name">{{ state.name }}</option>
          </select>
        </field-validate>
      </div>
    </ng-container>

    <button type="submit" class="btn btn-primary" (click)="submit()">
      <span>{{ isStudentSelected ? "Começar" : "Continuar" }}</span>
      <i *ngIf="isStudentSelected" class="bi bi-check-circle"></i>
    </button>
  </ng-template>
</form>



