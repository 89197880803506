import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DateRangeService } from 'src/app/services/calendar/date-range.service';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
})
export class CalendarViewComponent implements OnInit {
  @Input() month: number;
  @Input() year: number;
  @Input() currentDate: Date;
  @Input() selectedDates: Date[] = [];

  @Input() selectedRangeStartDate: Date | null = null;
  @Input() selectedRangeEndDate: Date | null = null;
  @Input() selectingStartDate = true;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(
    private dateRangeService: DateRangeService) { }

  ngOnInit(): void {
    this.selectedRangeStartDate = this.dateRangeService.selectedRangeStartDate;
    this.selectedRangeEndDate = this.dateRangeService.selectedRangeEndDate;
  }

  onSelectDate(date: Date): void {
    if (this.selectingStartDate) {
      this.selectedRangeStartDate = date;
      this.selectedRangeEndDate = date;
    } else {
      if (this.selectedRangeStartDate && date >= this.selectedRangeStartDate) {
        this.selectedRangeEndDate = date;
      }
    }

    this.selectingStartDate = !this.selectingStartDate;
    this.dateSelected.emit(date);
  }

  getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  isDateSelected(date: Date): boolean {
    return this.selectedDates.some(
      (selectedDate) =>
        selectedDate.getDate() === date.getDate() &&
        selectedDate.getMonth() === date.getMonth() &&
        selectedDate.getFullYear() === date.getFullYear()
    );
  }

  isDateInRange(date: Date): boolean {
    if (!this.selectedRangeStartDate || !this.selectedRangeEndDate) {
      return false;
    }
    return (
      date >= this.selectedRangeStartDate && date <= this.selectedRangeEndDate
    );
  }

  isDateInSelectedRange(date: Date): boolean {
    if (!this.selectedRangeStartDate || !this.selectedRangeEndDate) {
      return false;
    }
    return (
      date >= this.selectedRangeStartDate && date <= this.selectedRangeEndDate
    );
  }

  isCurrentDay(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  renderCalendarGrid(): Date[][] {
    const firstDay = new Date(this.year, this.month, 1).getDay();
    const daysInMonth = this.getDaysInMonth(this.year, this.month);
    const calendarGrid: Date[][] = [];

    let dayCounter = 1;
    for (let week = 0; week < 6; week++) {
      const weekDays: Date[] = [];
      for (let day = 0; day < 7; day++) {
        if ((week === 0 && day < firstDay) || dayCounter > daysInMonth) {
          weekDays.push(null);
        } else {
          const date = new Date(this.year, this.month, dayCounter);
          weekDays.push(date);
          dayCounter++;
        }
      }
      calendarGrid.push(weekDays);
      if (dayCounter > daysInMonth) {
        break;
      }
    }

    return calendarGrid;
  }

  isFutureDate(date: Date): boolean {
    const today = new Date();
    return date > today;
  }
}
