<div class="modal-header">
  <h4 class="modal-title">
    {{ establishment ? "Editar" : "Cadastrar" }} Estabelecimento
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Fechar"
    (click)="closeModal()"
  >
    <i class="bi bi-x icon__close"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="establishmentForm" class="modal-form">
    <div class="form-group">
      <div class="logo">
        <app-upload-clinic-logo [logoUrl]="logoUrl" (selectedImageUrl)="logoUrl = $event"></app-upload-clinic-logo>
      </div>
      <div>
        <field-validate
          fieldName="Nome do Estabelecimento"
          [showSuccessBackground]="false"
        >
          <input
            maxlength="80"
            minlength="3"
            type="text"
            formControlName="nome"
            placeholder="Informe o nome do estabelecimento"
            (change)="changedFields = true"
          />
        </field-validate>
        <field-validate fieldName="Classificação" [showSuccessBackground]="false">
          <select formControlName="classificacao" (change)="changedFields = true">
            <option value="null" selected disabled>Selecione</option>
            <option value="Clinica">Clinica</option>
            <option value="Consultório">Consultório</option>
            <option value="Hospital">Hospital</option>
            <option value="Hospital Escola">Hospital Escola</option>
          </select>
        </field-validate>
      </div>
    </div>
    <div class="form-group">
      <field-validate fieldName="E-mail" [showSuccessBackground]="false">
        <input
          maxlength="100"
          type="text"
          formControlName="email"
          placeholder="email@premiervet.com.br"
          (change)="changedFields = true"
        />
      </field-validate>
      <field-validate fieldName="CNPJ" [showSuccessBackground]="false">
        <input
          type="text"
          mask="00.000.000/0000-00"
          formControlName="cnpj"
          placeholder="00.000.000/0000-00"
          (change)="changedFields = true"
        />
      </field-validate>
    </div>
    <div class="form-group">
      <field-validate
        fieldName="Telefone Celular"
        [showSuccessBackground]="false"
      >
        <input
          type="text"
          mask="(00) 00000-0000||(00) 0000-0000"
          formControlName="telefoneMovel"
          placeholder="(00) 00000-0000"
          (change)="changedFields = true"
        />
      </field-validate>
      <field-validate fieldName="Telefone Fixo" [showSuccessBackground]="false">
        <input
          type="text"
          mask="(00) 0000-0000"
          formControlName="telefoneFixo"
          placeholder="(00) 0000-0000"
          (change)="changedFields = true"
        />
      </field-validate>
    </div>

    <div class="form-group cep">
      <field-validate fieldName="CEP" [showSuccessBackground]="false">
        <input
          mask="00000-000"
          [validation]="false"
          type="text"
          formControlName="cep"
          placeholder="00000-000"
          (change)="searchCep()"
          (change)="changedFields = true"
        />
      </field-validate>
      <a
        class="form-link"
        href="https://buscacepinter.correios.com.br/"
        target="__blank"
        >Não sabe seu CEP?</a
      >
    </div>

    <div class="form-group">
      <field-validate fieldName="Estado" [showSuccessBackground]="false">
        <select
          id="estado"
          name="estado"
          formControlName="estado"
          (change)="loadCities()"
          (change)="changedFields = true"
        >
          <option value="null" selected disabled>Selecione</option>
          <option *ngFor="let state of listStates" [value]="state.name">
            {{ state.name }}
          </option>
        </select>
      </field-validate>
      <field-validate fieldName="Cidade" [showSuccessBackground]="false">
        <select
          name="cidade"
          id="cidade"
          formControlName="idCidade"
          [attr.disabled]="
            establishmentForm.controls['estado'].value === null ? '' : null
          "
          (change)="changedFields = true"
        >
          <option [ngValue]="null" selected disabled>Selecione</option>
          <option *ngFor="let city of cities" [value]="city.id">
            {{ city.text }}
          </option>
        </select>
      </field-validate>
    </div>
    <div>
      <field-validate fieldName="Endereço" [showSuccessBackground]="false">
        <input
          maxlength="100"
          type="text"
          formControlName="logradouro"
          placeholder="Informe a rua ou logradouro"
          (change)="changedFields = true"
        />
      </field-validate>
    </div>
    <div class="form-group">
      <field-validate
        fieldName="Número"
        [showSuccessBackground]="false"
        customErrorMessage="Número inválido. Digite novamente."
      >
        <input
          maxlength="5"
          type="text"
          formControlName="numero"
          placeholder="Digite o número"
          (change)="changedFields = true"
        />
      </field-validate>
      <field-validate fieldName="Complemento" [showSuccessBackground]="false">
        <input
          maxlength="29"
          type="text"
          formControlName="complemento"
          placeholder="Ex: apto 42"
          (change)="changedFields = true"
        />
      </field-validate>
    </div>
    <div>
      <field-validate fieldName="Bairro" [showSuccessBackground]="false">
        <input
          maxlength="50"
          type="text"
          formControlName="bairro"
          placeholder="Informe o bairro"
          (change)="changedFields = true"
        />
      </field-validate>
    </div>
  </form>

  <div class="modal-footer show-shadow">
    <button class="button button-link" (click)="closeModal()">Cancelar</button>
    <button class="button button-primary" (click)="onSubmit()">
      <span>Salvar</span>
      <i class="bi bi-check-circle"></i>
    </button>
  </div>
</div>
