import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { delay, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {AuthenticationService} from '../services/authentication/authentication.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';

export const HideLoading = 'X-Skip-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService
            , private spinner: NgxSpinnerService
            , private deviceDetector: DeviceDetectorService
            , private router: Router) {}
  countLoading =  0;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const baseHeaders: any = {
      'Ocp-Apim-Subscription-Key': `${environment.subscriptionKey}`,
      'Ocp-Apim-Trace': `${environment.trace}`,
      'x-api-version': `${environment.apiVersion}`,
      Accept: 'application/octet-stream',
      'x-device-type': `${this.deviceDetector.deviceType}`,
      'x-browser': `${this.deviceDetector.browser}`
    };

    const showLoading = req.headers.get('loading');
    if (showLoading === 'true') {
      this.countLoading ++;
      if (this.countLoading === 1) {
        this.spinner.show();
      }
    }

    if (!this.authService.isLoggedIn()) {
       const requestCloned = req.clone({
         setHeaders: baseHeaders
       });
       return next.handle(requestCloned)
         .pipe(finalize(() => this.spinner.hide()));
     }

    const userInfo = this.authService.getUserInformation();
    baseHeaders.Authorization = `Bearer ${userInfo.accessToken}`;

    // request com login e com spinner.
    const request = req.clone({
      setHeaders: baseHeaders
    });
    return next.handle(request).pipe(
      delay(1000),
      finalize(() => {
        if (showLoading === 'true') {
          this.countLoading --;
          if (this.countLoading === 0) {
            this.spinner.hide();
          }
        }
      })
    );
  }
}
