<main #listRef>
  <button
  [ngStyle]="{ backgroundColor: isOpenFilterList ? '#99CAFF' : '' }"
  class="btn-filter" (click)="isOpenFilterList = !isOpenFilterList">
   <i *ngIf="hasIcon" class="bi bi-funnel icon-filter">
    <span class="count-filter-checked" *ngIf="countFiltersActive">{{countFiltersActive}}</span>
  </i>
   <label class="filter-description">{{descriptionButton}} </label>
  <i class="bi bi-caret-down-fill icon-open-container"></i>


  </button>
  <section  *ngIf="isOpenFilterList" class="list-filter__container">
    <div class="form-check" *ngFor="let list of listByFilter; let i = index">
      <label class="label-checkbox" [for]="i">
        {{ list.name }}
      </label>
      <input
        class="input-checkbox outline-primary"
        type="checkbox"
        [id]="i"
        [(ngModel)]="list.checked"
        (change)="onCheckboxChange()"

      />
    </div>

    <div class="clear-filters">
      <button [disabled]="countFiltersActive === 0" class="btn clear" (click)="removeFilters()">
        <i class="bi bi-trash"></i>
        <span>Limpar filtros</span>
      </button>
    </div>

  </section>
</main>

