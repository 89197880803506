<app-background urlNavigation="/#/login">
  <div class="form-container" *ngIf="!success && !tokenInvalid">
    <form
      id="id-reg-password-form"
      class="form"
      [formGroup]="newPassForm"
    >
      <h1 id="id-reg-password-title" class="title-reg-password">
        {{ createNewPassword ? "Crie sua senha" : "Redefinir senha"}}
      </h1>
      <div class="form-group">
        <field-validate fieldName="Senha">
          <input
            [type]="hidePassword ? 'password' : 'text'"
            id="password"
            formControlName="password"
            placeholder="Digite sua senha"
            maxlength="13"
            noWhiteSpace
            [nextField]="confirmPassword"
          />
        </field-validate>
        <button
          type="button"
          (click)="hidePassword = !hidePassword"
          class="hide-password"
        >
          <i
            [ngClass]="
              hidePassword
                ? 'bi bi-eye icon-password'
                : 'bi bi-eye-slash icon-password'
            "
          ></i>
        </button>
      </div>
      <div class="form-group confirm-password">
        <field-validate fieldName="Confirme sua senha">
          <input
            #confirmPassword
            [type]="hideConfirmPassword ? 'password' : 'text'"
            id="confirmPassword"
            formControlName="confirmPassword"
            [placeholder]="
              createNewPassword ? 'Digite novamente sua senha' : 'Digite novamente sua nova senha'
            "
            maxlength="13"
            noWhiteSpace
          />
        </field-validate>
        <span
          *ngIf="
            newPassForm.controls['confirmPassword'].touched &&
            newPassForm.controls['confirmPassword'].hasError('NoPassswordMatch')
          "
          class="span-error"
        >As senhas digitadas não estão iguais. Digite novamente.</span
        >
        <button
          type="button"
          (click)="hideConfirmPassword = !hideConfirmPassword"
          class="hide-password"
        >
          <i
            [ngClass]="
              hideConfirmPassword
                ? 'bi bi-eye icon-password'
                : 'bi bi-eye-slash icon-password'
            "
          ></i>
        </button>
      </div>
      <div class="container-input">
        <section
          id="id-reg-password-description-container"
          class="description-container"
        >
          <ul>
            <li class="description__list">
              <i class="bi icon_description"
                 [class.bi-check]="!newPassForm.controls['password'].hasError('minlength')"
                 [ngClass]="{
                 'success': !newPassForm.controls['password'].hasError('required') && !newPassForm.controls['password'].hasError('minlength'),
                 'bi-x error': newPassForm.controls['password'].touched && newPassForm.controls['password'].hasError('required') || newPassForm.controls['password'].hasError('minlength')
                }"
              ></i>
              <caption class="description">
                Mínimo de 7 caracteres
              </caption>
            </li>
            <li class="description__list">
              <i class="bi icon_description"
                 [class.bi-check]="!newPassForm.controls['password'].hasError('hasCapitalCase')"
                 [ngClass]="{
                 'success': !newPassForm.controls['password'].hasError('required') && !newPassForm.controls['password'].hasError('hasCapitalCase'),
                 'bi-x error': newPassForm.controls['password'].touched && newPassForm.controls['password'].hasError('required') || newPassForm.controls['password'].hasError('hasCapitalCase')
                }"
              ></i>
              <caption class="description">
                Letra maiúscula
              </caption>
            </li>
            <li class="description__list">
              <i class="bi icon_description"
                 [class.bi-check]="!newPassForm.controls['password'].hasError('hasSmallCase')"
                 [ngClass]="{
                 'success': !newPassForm.controls['password'].hasError('required') && !newPassForm.controls['password'].hasError('hasSmallCase'),
                 'bi-x error':  newPassForm.controls['password'].touched && newPassForm.controls['password'].hasError('required') || newPassForm.controls['password'].hasError('hasSmallCase')
                }"
              ></i>
              <caption class="description">
                Letra minúscula
              </caption>
            </li>
            <li class="description__list">
              <i class="bi icon_description"
                 [class.bi-check]="!newPassForm.controls['password'].hasError('hasNumber')"
                 [ngClass]="{
                 'success': !newPassForm.controls['password'].hasError('required') && !newPassForm.controls['password'].hasError('hasNumber'),
                 'bi-x error': newPassForm.controls['password'].touched && newPassForm.controls['password'].hasError('required') || newPassForm.controls['password'].hasError('hasNumber')
                }"
              ></i>
              <caption class="description">
                Número
              </caption>
            </li>
            <li class="description__list">
              <i class="bi icon_description"
                 [class.bi-check]="!newPassForm.controls['password'].hasError('hasSpecialCharacters')"
                 [ngClass]="{
                 'success': !newPassForm.controls['password'].hasError('required') && !newPassForm.controls['password'].hasError('hasSpecialCharacters'),
                 'bi-x error':  newPassForm.controls['password'].touched && newPassForm.controls['password'].hasError('required') || newPassForm.controls['password'].hasError('hasSpecialCharacters')
                }"
              ></i>
              <caption class="description">
                Caractere especial
              </caption>
            </li>
          </ul>
        </section>
        <button
          id="id-reg-password-btn-submit"
          type="submit"
          class="btn-submit"
          (click)="onSubmit()"
        >
          Continuar
        </button>
      </div>
    </form>
  </div>

  <div class="form-container" *ngIf="success">
    <div class="message">
      <img
        src="../../../../assets/images/registration/Success-registration.svg"
        alt="Success image"
      />
      <div class="message-container">
        <h2 class="message-title">{{ createNewPassword ? 'Seu cadastro foi criado' : 'Sua senha foi alterada' }} com
          sucesso!</h2>
        <p>Eba! Agora está tudo pronto para acessar o PremieRvet®</p>
        <button class="btn-submit" (click)="goToLogin()">
          Fazer Login
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="tokenInvalid">
    <h4>Token inválido</h4>
  </div>
</app-background>
