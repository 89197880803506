<app-background>
  <div class="form-container">
    <form
    id="id-login-form"
    class="form"
    [formGroup]="termsForm"
    >
      <div *ngIf="enableButton">
        <h1 id="id-login-title" class="form-title"> Baixar orientação</h1>
        <div class="block-container" >
          <label for="download-guindance-pdf" class="form-label">{{descriptionLabel}}</label>
          <div class="form-check" *ngIf="!checked">
            <input class="form-check-input" type="checkbox" value="" id="download-guindance-pdf" (click)="handleSelected($event)" formControlName="acceptedTerms">
            <label for="download-guindance-pdf" class="form-check-label">
              Aceito os <a [href]="termsPdfLink" target="_blank">termos e as politicas de privacidade</a>
            </label>
          </div>
          <button   (click)="submit()" class="btn-download"> <i class="bi bi-download"></i> Baixar orientação</button>
          <caption *ngIf="!accepted" class="msg-error">Aceite os termos para baixar a orientação </caption>
        </div>
      </div>
    </form>
  </div>
</app-background>
