import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  HeaderTitleService
} from '../../../home/main/components/header-mobile/title-behavior-service/header-title.service';
import { PwaService } from '../../../../services/pwa/pwa.service';

@Component({
  selector: 'app-pwa-install',
  templateUrl: './pwa-install.component.html',
  styleUrls: ['./pwa-install.component.scss']
})
export class PwaInstallComponent implements OnInit {

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.notificationClosed = true;
      localStorage.setItem('notificationClosed', 'true');
    }
    else {
      this.addToHomeScreen();
    }
  }

  notificationClosed: boolean;
  modalPwaPlatform: string | undefined;
  modalPwaEvent: any;
  showInstallNotification = false;
  constructor(private platform: DeviceDetectorService,
    private headerTitleService: HeaderTitleService,
    private pwaService: PwaService,
    private eRef: ElementRef) {
  }

  ngOnInit(): void {
    this.notificationClosed = JSON.parse(localStorage.getItem('_notificationClosed'));
    this.headerTitleService.getTitle().subscribe(resp => {
      this.showInstallNotification = resp === 'Home' && !this.notificationClosed;
      this.loadModalPwa();
    });
  }
  private loadModalPwa(): void {
    switch (this.platform.os) {
      case 'Android':
        window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.modalPwaEvent = event;
          this.modalPwaPlatform = 'ANDROID';
          this.pwaService.setDownloadPwaEvent(event);
        });
        break;
      case 'iOS':
        if (this.platform.browser === 'Safari') {
          const isInStandaloneMode = ('standalone' in window.navigator) && ((window.navigator as any).standalone);
          if (!isInStandaloneMode) {
            this.modalPwaPlatform = 'IOS';
          }
        }
        break;
      default:
          this.modalPwaPlatform = '';
        break;
    }
  }
  public closeNotification(): void {
    this.notificationClosed = true;
    localStorage.setItem('_notificationClosed', 'true');
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }
}
