<app-background [urlNavigation]="urlNavigation">
  <div class="form-container">
    <form id="id-login-form" class="form" [formGroup]="loginForm">
      <h1 id="id-login-title" class="form-title">Acesse sua conta</h1>
      <div class="d-flex flex-column">
        <div *ngIf="incorrectData" class="error-card">
          <i class="bi bi-info-circle"></i>
          <p class="error-msg">Usuário ou senha inválidos.</p>
        </div>
        <div>
          <field-validate fieldName="E-mail" [externalError]="incorrectData" [showSuccessBackground]="!incorrectData">
            <input #email type="text" id="id-login-input-email" formControlName="email" />
          </field-validate>
        </div>
        <div class="position-relative">
          <field-validate fieldName="Senha" maxlenght="13" [externalError]="incorrectData"
            [showSuccessBackground]="!incorrectData" [showPaddingBottom]="false">
            <input #password [type]="hide? 'password' : 'text'" id="id-login-input-password" formControlName="password"
              maxlength="13" />
            <button id="id-login-btn-password" p-suffix type="button" (click)="hide = !hide" class="button-password">
              <i id="id-login-btn-password-icon"
                [ngClass]="hide ? 'bi bi-eye icon-password':'bi bi-eye-slash icon-password'"></i>
            </button>
          </field-validate>
        </div>
        <div id="id-login-reset-password-container" class="text-right">
          <a id="id-login-reset-password" class="reset-login" routerLink="/recuperar-senha">Esqueceu sua senha ?</a>
        </div>
      </div>
      <button id="id-login-btn-submit" type="submit" class="btn-submit" (click)="submit()">Entrar</button>
      <div class="text-center">
        <span id="id-login-without-credentials" class="without-credentials">
          Não possui uma conta?
          <a routerLink="/cadastro" class="without-credentials__link">Cadastre-se agora </a>
        </span>
      </div>
    </form>
    <div class="logo-login" *ngIf="mobileScreen"><img src="'../../../../../../assets/images/logo-premiervet-r.png" alt="Logo-premierVet"></div>
  </div>
</app-background>
