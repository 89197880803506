import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent {
  @Input() bottom = 26;
  scrollTop:number;
  constructor() { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.scrollTop = window.scrollY;
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
