<main class="container-generic-table">
  <table class="container-table">
    <tr class="header-table">
      @for (header of hdrArr; track $index) {
        <th class="title-header">{{ header.name }}</th>
      }
    </tr>

    @for (item of dataTable; track $index) {
      <tr>
        @for (header of hdrArr; track $index) {

          <td
          [ngStyle]="hdrArr.length === 5 ? {width: '20%'} : {width:'25%'}"
          [ngClass]=" item.currentOrientation? 'info-table highlighted-line' : 'info-table' "
          >
            <ng-container
              *ngIf="header.template !== undefined"
              [ngTemplateOutlet]="header.template"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
            {{
              header.template !== undefined
                ? ''
                : getPropByString(item, header.prop)
            }}
          </td>
        }
      </tr>
    }
  </table>
</main>
