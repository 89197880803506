import { Component, HostListener, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { RegisterAccountModel } from "../../../services/authentication/models/register-account-model";
import { EmailVerificationModel } from 'src/app/services/authentication/models/email-verification-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Ga4Service } from '../../../services/ga4/ga4.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm: UntypedFormGroup;
  termsPdfLink: string = environment.urlTermsPdf;
  registerSuccessfully: boolean = false;
  mobileScreen: boolean;
  screenWidth: number;
  token: string = '';
  registrationId: string;
  showPassword: boolean = false;
  completeRegister = false;
  isMinLengthValid: boolean = false;
  isUppercaseValid: boolean = false;
  isLowercaseValid: boolean = false;
  isNumberValid: boolean = false;
  isSpecialCharValid: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private ga4Service: Ga4Service
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.setRegistrationId();
    this.initializeForm();
    this.getFormStorage();
  }

  initializeForm() {
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/)]],
    });

    this.registrationForm.get('password')?.valueChanges.subscribe((value) => {
      this.validatePassword(value);
    });
  }

  setFormStorage(field: string) {
    const email = this.registrationForm.get('email')?.value?.toLowerCase();
    const password = this.registrationForm.get('password')?.value;
    this.registrationForm.controls['email'].setValue(email);
    this.registrationForm.controls['password'].setValue(password);
    localStorage.setItem(
      'registrationForm',
      JSON.stringify(this.registrationForm.value)
    );

    this.emitFieldChange(field);
  }

  emitFieldChange(field: string): void {
    const event = `${field}_preenchido`;
    this.ga4Service.emit(event, this.registrationId);
  }

  getFormStorage() {
    this.registrationForm.patchValue(
      JSON.parse(localStorage.getItem('registrationForm'))
    );
  }

  onSubmit() {
    if (this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();

      return;
    }
    this.registerAccount();
  }

  registerAccount() {
    this.spinner.show();
    const register = new RegisterAccountModel();
    register.email = this.registrationForm.get('email')?.value;
    register.password = this.registrationForm.get('password')?.value;

    this.authenticationService.registerAccount(register).subscribe(
      () => {
        this.registerSuccessfully = true;
        this.ga4Service.emit('cadastro_iniciado');
        localStorage.removeItem('registrationForm');
      },
      () => {
        this.spinner.hide();
      }
    )
  }


  resendEmail() {
    const validateRequest: EmailVerificationModel = {
      verificationToken: this.token,
      email: this.registrationForm.get('email')?.value
    };

    this.authenticationService.verificationEmail(validateRequest).subscribe(
      (res) => {
        this.toastrService.success(
          'E-mail enviado com sucesso. Verifique seu spam.'
        );
        this.registerSuccessfully = true;
        localStorage.removeItem('registrationForm');
      },
      (error) => {
        this.toastrService.error('Token invalido.', 'Erro');
      }
    );
  }

  pad(num: string, size: number, char: string): string {
    let s = num + '';
    while (s.length < size) s = char + s;
    return s;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number) {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576
      ? (this.mobileScreen = false)
      : (this.mobileScreen = true);
  }

  setRegistrationId(): void {
    this.registrationId = localStorage.getItem('registrationId');
    if (!this.registrationId) {
      this.registrationId = uuidv4();
      localStorage.setItem('registrationId', this.registrationId);
    }
  }

  onBack(): void {
    this.ga4Service.emit('voltar_acionado', this.registrationId);
  }

  validatePassword(password: string) {
    this.isMinLengthValid = password.length >= 8;
    this.isUppercaseValid = /[A-Z]/.test(password);
    this.isLowercaseValid = /[a-z]/.test(password);
    this.isNumberValid = /\d/.test(password);
    this.isSpecialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  }

  onTokenValidated(): void {
    this.registerSuccessfully = false;
    this.completeRegister = true;
  }
}
