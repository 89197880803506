import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-soon',
  templateUrl: './soon.component.html',
  styleUrls: ['./soon.component.scss']
})
export class SoonComponent {
  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/']);
  }
}
