<div class="logo-container" [ngClass]="size">
  <div *ngIf="!imageUrl" class="upload-container" (click)="selectFile()">
    <img src="../../assets/icons/icon-img.svg" alt="Imagem" />
    <h6 class="upload-title">{{ label }}</h6>
    <p class="upload-info" [ngClass]="{'error': error}">
      <i *ngIf="error" class="bi bi-exclamation-circle"></i>
      <span>Tamanho máximo de {{ maxFileSizeMB }}mb</span>
    </p>
  </div>

  <div *ngIf="imageUrl" class="image-container">
    <img [src]="imageUrl" alt="Logo">
    <button class="btn delete" (click)="removeLogo()">
      <i class="bi bi-trash-fill"></i>
    </button>
  </div>
</div>
<input #inputUpload type="file" [attr.accept]="acceptedFiles" hidden (change)="uploadFile($event)">
