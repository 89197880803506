import {DatePipe} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {defineLocale, ptBrLocale} from 'ngx-bootstrap/chronos';
import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {LookupService} from 'src/app/services/lookup/lookup.service';
import {
  BreedModel,
  BreedSizeModel,
} from 'src/app/services/lookup/models/breed-model';
import {LookupBaseModel} from 'src/app/services/lookup/models/lookup-base-model';
import {PetModel} from 'src/app/services/pet/models/pet-model';
import {PetService} from 'src/app/services/pet/pet.service';
import {TutorModel} from 'src/app/services/tutor/models/tutor-model';
import {SpecieEnum} from '../../enums/specie.enum';

defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-pet-modal',
  templateUrl: './pet-modal.component.html',
  styleUrls: ['./pet-modal.component.scss'],
  providers: [DatePipe],
})
export class PetModalComponent implements OnInit, AfterViewInit {
  petForm: UntypedFormGroup;
  pet: PetModel;
  tutor: TutorModel;
  unsavedPet: PetModel;
  changedFields = false;
  unsavedData = new Subject<PetModel>();
  savedPet = new Subject<PetModel>();
  tutorId: number;
  tutorName: string;

  species: LookupBaseModel[] = [];
  breeds: BreedModel[] = [];
  sizes: BreedSizeModel[] = [];

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    maxDate: new Date(),
    showWeekNumbers: false,
    adaptivePosition: true,
  };

  formSubmitted = false;
  phoneWidth = 576;
  showMobileDatePicker = false;
  isMobile = false;
  petBirthDate: Date;
  specieEditPet: string;

  @ViewChild('datepicker', {static: true}) datepicker: ElementRef;

  constructor(
    private petService: PetService,
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private bsLocaleService: BsLocaleService,
    private lookupService: LookupService,
    private datePipe: DatePipe,
    private toastrService: ToastrService
  ) {
    this.bsLocaleService.use('pt-br');
  }

  ngOnInit(): void {
    if (this.tutor) {
      this.tutorId = this.tutor.id;
      this.tutorName = this.tutor.nome;
    }
    this.inicializeForm();
    this.listenHasUnsavedData();
    this.loadSpecies();
    this.isMobile = window.innerWidth < this.phoneWidth;
  }

  ngAfterViewInit(): void {
    if (this.isMobile) {
      this.datepicker.nativeElement.readOnly = true;
    }
  }

  inicializeForm(): void {
    this.petForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required, Validators.maxLength(40)]],
      petEspecie: [null, [Validators.required]],
      racaId: [null, [Validators.required]],
      genero: [null, [Validators.required]],
      porte: [null, [Validators.required]],
      dataNascimento: [null, [Validators.required]],
    });

    if (this.pet && !this.unsavedPet) {
      this.petForm.patchValue({
        id: this.pet.id,
        nome: this.pet.nome,
        petEspecie: this.pet.petEspecie === 'Dog' ? SpecieEnum.DOG : SpecieEnum.CAT,
        racaId: this.pet.racaId,
        genero: this.pet.genero,
        porte: this.pet.porte,
        dataNascimento: this.datePipe.transform(
          String(this.pet.dataNascimento).substring(0, 10),
          'dd-MM-yyyy'
        ),
      });
      this.specieEditPet = this.pet.petEspecie === 'Dog' ? 'Cão' : 'Gato';
    }

    if (this.unsavedPet) {
      this.petForm.patchValue(this.unsavedPet);
    }

    if (this.pet || this.unsavedPet) {
      this.loadBreeds(this.petForm.controls.petEspecie.value);
    }
  }

  listenHasUnsavedData(): void {
    this.bsModalRef.onHidden.subscribe(() => {
      if (this.changedFields && window.innerWidth > this.phoneWidth) {
        this.unsavedData.next(this.petForm.getRawValue());
      }
    });

    if (this.unsavedPet) {
      this.changedFields = true;
    }
  }

  loadSpecies(): void {
    this.lookupService.getSpecies().subscribe((res) => {
      this.species = res.items;
    });
  }

  loadBreeds(specieId: number): void {
    if (specieId) {
      this.lookupService.getBreeds(specieId).subscribe((res) => {
        this.breeds = res.items;
        if (!this.pet && !this.unsavedPet) {
          this.petForm.controls.racaId.setValue(null);
          this.petForm.controls.porte.setValue(null);
        } else {
          this.loadSizes(this.petForm.controls.racaId.value);
        }
      });
    } else {
      this.breeds = [];
      this.sizes = [];
    }
  }

  loadSizes(breedId: number): void {
    if (!this.pet && !this.unsavedPet) {
      this.petForm.controls.porte.setValue(null);
    }

    const specie = this.petForm.controls.petEspecie;
    if (Number(specie.value) === SpecieEnum.CAT) {
      this.petForm.controls.porte.setValue(5);
    } else {
      const selectedBreed = this.breeds.find(x => x.id === Number(breedId));
      this.sizes = selectedBreed?.breedSizesModel || [];
    }
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.petForm.invalid) {
      this.petForm.markAllAsTouched();
      this.toastrService.error(
        window.innerWidth > this.phoneWidth
          ? 'Para continuar é necessário preencher os campos obrigatórios'
          : 'Para continuar preencha os campos obrigatórios'
      );
      return;
    }

    const pet = this.petForm.getRawValue() as PetModel;
    let dataNascimento;
    if (String(pet.dataNascimento).length === 10) {
      dataNascimento = this.formatDate(String(pet.dataNascimento));
    } else {
      dataNascimento = this.datePipe.transform(
        new Date(pet.dataNascimento),
        'yyyy-MM-dd'
      );
    }
    pet.dataNascimento = new Date(dataNascimento);
    pet.tutorId = this.tutorId;

    if (this.pet) {
      this.editPet(pet);
    } else {
      this.createPet(pet);
    }
  }

  createPet(pet: PetModel): void {
    delete pet.id;
    this.petService.savePet(pet).subscribe((res) => {
      this.savedPet.next(res.item);
      this.toastrService.success('Pet cadastrado com sucesso.');
      this.changedFields = false;
      this.closeModal();
    });
  }

  editPet(pet: PetModel): void {
    this.petService.updatePet(pet.id, pet).subscribe((res) => {
      const savedPet = res.item;
      savedPet.petEspecie =
        +res.item.petEspecie === SpecieEnum.DOG ? 'Dog' : 'Cat';
      this.savedPet.next(res.item);
      this.toastrService.success('Pet salvo com sucesso.');
      this.changedFields = false;
      this.closeModal();
    });
  }

  private formatDate(date: string): string {
    const dateParts = date.split('-');
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }

  closeModal(): void {
    if (window.innerWidth > this.phoneWidth) {
      this.bsModalRef.hide();
      return;
    }

    if (this.changedFields) {
      this.unsavedData.next(this.petForm.getRawValue());
    } else {
      this.bsModalRef.hide();
    }
  }

  handleMobileBirthDatePicker(): void {
    this.isMobile = window.innerWidth < this.phoneWidth;
    if (this.isMobile) {
      this.getPetBirthDate();
      this.showMobileDatePicker = true;
    }
  }

  getPetBirthDate(): void {
    const date = this.petForm.controls.dataNascimento.value;
    if (date instanceof Date) {
      this.petBirthDate = date;
    } else {
      if (date) {
        const dateSplitted = date.split('-');

        this.petBirthDate = new Date(
          parseInt(dateSplitted[2], 10),
          parseInt(dateSplitted[1], 10) - 1,
          parseInt(dateSplitted[0], 10)
        );
      }
    }
  }

  setPetBirthDate(date: Date): void {
    this.petForm.controls.dataNascimento.setValue(date);
    this.showMobileDatePicker = false;
  }

  setPetSize(): void {
    if (this.pet.petEspecie === 'Dog') {
      this.petForm.controls.porte.setValue(null);
    }
  }

  onInput(event: any): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9 ]/g, '');
  }
}
