import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppListResponse } from '../shared/app.response';
import { BaseListRequest } from '../shared/base-list-request';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  NotificationModel,
  RemoveNotificationModel,
  SendNotificationModel,
} from './models/notification-model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications = new BehaviorSubject<boolean>(false);
  message:any = null;

  private unreadNotificationsCountSource = new BehaviorSubject<number>(0);
  unreadNotificationsCount$ =
    this.unreadNotificationsCountSource.asObservable();

  private apiUrl = `${environment.baseApimUrl}/notification`;

  private toastrConfig: Partial<IndividualConfig> = {
    closeButton: true,
    timeOut: 5000,
    progressBar: true,
    progressAnimation: 'decreasing',
    positionClass: 'toast-top-right',
  };

  constructor(private toastrService: ToastrService, private http: HttpClient) {}

  error(message: string): void {
    this.toastrConfig.timeOut = message.length >=70 ? 7000 : 4500;
    this.toastrService.error(message, '', this.toastrConfig);
  }

  custom(message: string, title: string, type?: string): ActiveToast<any> {
    const config: Partial<IndividualConfig> = {
      closeButton: true,
      timeOut: 10000,
      progressBar: true,
      progressAnimation: 'decreasing',
      positionClass: 'toast-top-right',
    };
    config.toastClass = 'custom_toast';

    if (title?.length > 25) {
      title = title.substring(0, 25) + '...';
    }

    if (message?.length > 50) {
      message = message.substring(0, 50) + '...';
    }

    let toastType = 'toast-warning';
    switch (type) {
      case 'sucess':
        toastType = 'toast-sucess';
        break;
      case 'error':
        toastType = 'toast-error';
        break;
      default:
        break;
    }

    return this.toastrService.show(message, title, config, toastType);
  }

  success(message: string): void {
    this.toastrConfig.timeOut = 2500;
    this.toastrService.success(message, '', this.toastrConfig);
  }

  warning(message: string): void {
    this.toastrConfig.timeOut = message.length >= 70 ? 7000 :  4000;
    this.toastrService.warning(message, '', this.toastrConfig);
  }

  checkNotifications(): Observable<boolean> {
    const url = `${this.apiUrl}/checknotifications`;
    return this.http.get<boolean>(url);
  }

  getNotifications(
    itensPerPage = 10,
    pageNumber = 0
  ): Observable<AppListResponse<NotificationModel>> {
    const url = `${this.apiUrl}/getnotifications`;
    const parameters: BaseListRequest = {
      PageNumber: pageNumber,
      PageSize: itensPerPage,
    };

    return this.http.post<AppListResponse<NotificationModel>>(url, parameters);
  }

  unreadNotificationsCount(): Observable<number> {
    const url = `${this.apiUrl}/countUnreadNotifications`;

    return this.http.get<number>(url);
  }

  setUnreadNotificationsCount(count: number): void {
    this.unreadNotificationsCountSource.next(count);
  }

  updateLastView(): Observable<boolean> {
    const url = `${this.apiUrl}/updateLastView`;
    return this.http.get<boolean>(url);
  }

  hasNotifications(): Observable<boolean> {
    return this.notifications.asObservable();
  }

  sendNotification(
    title: string,
    description: string,
    type: number,
    secret: string,
    typeNotification: number
  ): Observable<boolean> {
    const url = `${this.apiUrl}/sendNotification`;

    const parameters: SendNotificationModel = {
      title,
      text: description,
      type,
      secret,
      typeNotification
    };
    return this.http.post<boolean>(url, parameters);
  }

  getAllNotifications(): Observable<Array<NotificationModel>> {
    const url = `${this.apiUrl}/getAllNotifications`;
    return this.http.get<Array<NotificationModel>>(url);
  }

  removeNotification(id: number, secret: string): Observable<boolean> {
    const url = `${this.apiUrl}/remove`;
    const parameters: RemoveNotificationModel = {
      id,
      secret,
    };
    return this.http.post<boolean>(url, parameters);
  }

  sendToken(notificationKey: string) {
    const url = `${this.apiUrl}/saveNotificationKey`;
    const parameters = {
      groupId: '',
      groupName: '',
      notificationKey: notificationKey
    };
    return this.http.post<boolean>(url, parameters);
  }

  getPushToken() {
    if(localStorage.getItem('userToken') != '' && localStorage.getItem('userToken') != null)
      {
        this.requestPermission();
        this.listen();
      }
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
           this.sendToken(currentToken).subscribe(() => { console.info('Token available'); });
         } else {
           console.log('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
  }
  
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message=payload;
    });
  }
}
