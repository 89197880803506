import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { PetPhysicalActivityModel } from '../../../../services/pet/models/pet-physical-activity-model';
import { SpecieEnum } from '../../enums/specie.enum';

@Component({
  selector: 'app-pet-activity-level',
  templateUrl: './pet-activity-level.component.html',
  styleUrls: ['./pet-activity-level.component.scss']
})
export class PetActivityLevelComponent implements OnChanges, OnInit {
  @Input() label: string;
  @Input() showPhysicalActivities = true;
  @Input() physicalActivities: PetPhysicalActivityModel[] = [];
  @Input() required = true;
  @Input() goalOrientationSelected: number;
  @Input() speciePet : SpecieEnum;

  @Input() selectedActivityId: number;
  @Input() activityIdSelected: number;

  @Output() activitySelected  = new EventEmitter<number>();

  ngOnInit() {
    this.selectedActivityId = this.activityIdSelected;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.goalOrientationSelected && this.activityIdSelected != null) {
      this.selectedActivityId = null;
    }
  }
  handleOptionSelected(): void {
    this.activitySelected.next(this.selectedActivityId);
  }
}
