import { Component, OnInit, Renderer2 } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

const animation = trigger('effect', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
    }),
    animate(
      '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      style({
        transform: 'translateX(0%)',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '200ms cubic-bezier(.1,.5,.65,.99)',
      style({ transform: 'translateX(100%)' })
    ),
  ]),
]);

@Component({
  selector: 'app-generic-nav-drawer',
  templateUrl: './generic-nav-drawer.component.html',
  styleUrls: ['./generic-nav-drawer.component.scss'],
  animations: [animation],
})
export class GenericNavDrawerComponent {
  public isOpen = false;
  constructor(private renderer2: Renderer2) {}

  public toggleVisibility(): void {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.insertBackDrop();
      document.body.style.overflow = 'hidden';
    } else {
      const backdrops = document.querySelectorAll('.back-drop');
      const backdrop = backdrops[backdrops.length - 1];
      backdrop.remove();
      document.body.style.overflow = backdrops.length > 1 ? 'hidden' : 'auto';
    }
  }
  public insertBackDrop(): void {
    const backdropElement = this.renderer2.createElement('div') as HTMLElement;

    backdropElement.classList.add('back-drop');

    backdropElement.addEventListener('click', () => {
      this.toggleVisibility();
    });

    const htmlBody = document.body;
    htmlBody.insertAdjacentElement('beforeend', backdropElement);
  }
}
