import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard  {
  constructor(private authServiceUser: AuthenticationService, private router: Router) { }
  canActivate(): boolean {
    if (!this.authServiceUser.isLoggedIn()) {
      this.authServiceUser.logoff();
      return false;
    }
    return true;
  }
}
